import React, { Fragment, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import EventsSection from "../../components/EventsSection/EventsSection";
import Footer from "../../components/footer/Footer";
import GallerySection from "../../components/GallerySection/GallerySection";
import Navbar from "../../components/Navbar/Navbar";
import Scrollbar from "../../components/scrollbar/scrollbar";
import VideoArea from "../../components/VideoArea/VideoArea";
import BlogList from "../../components/BlogList/BlogList";

//
import ptn2 from "../../images/main-slider/pattern-1.png";
import ptn from "../../images/main-slider/pattern-2.png";
import slide1 from "../../images/slides/slide1.jpeg";
import slide2 from "../../images/slides/slide2.jpeg";
import slide3 from "../../images/slides/slide3.jpg";
import slide4 from "../../images/slides/slide4.jpeg";
import slide5 from "../../images/slides/slide5.jpeg";

import { Roll } from "react-awesome-reveal";
import ModalVideo from "react-modal-video";
import Teams from "../../api/Teams";
import abimg from "../../images/background/pattern-1.png";
import abimg2 from "../../images/background/pattern-2.png";
import abimg3 from "../../images/background/pattern-3.png";
import shape1 from "../../images/background/pattern-4.png";
import shape2 from "../../images/background/pattern-6.png";
import sIcon from "../../images/background/pattern-7.png";
import sIcon2 from "../../images/background/pattern-8.png";
import eventImage from "../../images/UNTourism-logo.png";

const images = [
  {
    id: 1,
    image: slide1,
  },
  {
    id: 2,
    image: slide2,
  },
  {
    id: 3,
    image: slide3,
  },
  {
    id: 4,
    image: slide4,
  },
  {
    id: 5,
    image: slide5,
  },
];

const eventGallery = [
  {
    day: 1,
    image: [
      { src: "https://18etpsarawak.com/images/day1_1.jpg" },
      { src: "https://18etpsarawak.com/images/day1_2.jpg" },
      { src: "https://18etpsarawak.com/images/day1_3.jpg" },
      { src: "https://18etpsarawak.com/images/day1_4.jpg" },
      { src: "https://18etpsarawak.com/images/day1_5.jpg" },
      { src: "https://18etpsarawak.com/images/day1_6.jpg" },
      { src: "https://18etpsarawak.com/images/day1_7.jpg" },
      { src: "https://18etpsarawak.com/images/day1_8.jpg" },
    ],
  },
  {
    day: 2,
    image: [],
  },
  {
    day: 3,
    image: [],
  },
  {
    day: 4,
    image: [],
  },
];

const HomePage = () => {
  const galleryArray = eventGallery.find((x) => x.day === 1);

  var settings = {
    dots: false,
    arrows: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const [isOpen, setOpen] = useState(false);

  const featres = [
    {
      fIcon: "flaticon-document",
      title: "Visa Application",
      description: [
        "<p>All delegates must have valid passports or relevant travel documents for entry into Malaysia.</p>",
        "<p>Citizens(Ordinary/Official/Diplomatic passport) of member states in Asia and the Pacific from the following countries are allowed to enter Malaysia without a visa: Brunei Darussalam, Cambodia, China (Including Hong Kong and Macau), Fiji, India, Indonesia, Iran, Japan, Laos, Maldives, Mongolia, Palau, Papua New Guinea, Philippines, Samoa, South Korea, Thailand, Timor Leste, Vanuatu, and Vietnam.</p>",
        "<p>Citizens (Ordinary/Official/Diplomatic passport) from the countries in the diagram below must obtain an e-visa before boarding the flight.</p>",
      ],
    },
    {
      fIcon: "flaticon-folder",
      title: "Malaysia Digital Arrival Card (MDAC)",
      description: [
        "<p>All foreign travelers (both visa or visa-free travelers) must complete the <a href='https://imigresen-online.imi.gov.my/mdac/main' target='_blank' rel='noopener'>Malaysia Digital Arrival Card (MDAC)</a> within three (3) days prior to their scheduled arrival in Malaysia EXCEPT Diplomatic and Official Passport Holders.</p>",
      ],
    },
  ];

  return (
    <Fragment>
      <Navbar />
      <section className="slider-one" id="home">
        <div
          className="slider-one_pattern-two"
          style={{ backgroundImage: `url(${ptn})` }}></div>
        <div className="main-slider-carousel">
          <Slider {...settings}>
            {images.map((item, index) => {
              const date = "28-31 October 2024";

              const { image } = item;

              const day = date.split(" ")[0];

              const month = date.split(" ")[1];

              const year = date.split(" ")[2];

              return (
                <div className="slider-one_slide" key={index}>
                  <div
                    className="slider-one_image-layer"
                    style={{ backgroundImage: `url(${image})` }}></div>
                  <div
                    className="slider-one_pattern"
                    style={{ backgroundImage: `url(${ptn2})` }}></div>
                  <div className="auto-container">
                    {/*  Content Column */}
                    <div className="slider-one_content">
                      <div className="slider-one_content-inner">
                        <div className="slider-one_title">
                          Kuching, Malaysia
                        </div>
                        <div className="slider-one_date">
                          <span>{day}</span> {month} <br /> {year}
                        </div>
                        <h2 className="slider-one_heading">
                          The 18th UN Tourism Asia/Pacific Executive Training
                          Programme
                        </h2>
                        {/* <div className="slider-one_buttons">
                        <Link
                          to="/register"
                          className="theme-btn btn-style-one">
                          <span className="btn-wrap">
                            <span className="text-one">{button}</span>
                            <span className="text-two">{button}</span>
                          </span>
                        </Link>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>

      <VideoArea />
      
      <BlogList />
      
      <GallerySection image={galleryArray} />

      <section className="about-one" id="about">
        <div
          className="about-one_pattern-one"
          style={{ backgroundImage: `url(${abimg})` }}
        />
        <div
          className="about-one_pattern-two"
          style={{ backgroundImage: `url(${abimg3})` }}
        />
        <div
          className="about-one_pattern-three"
          style={{ backgroundImage: `url(${abimg2})` }}
        />
        <div className="auto-container">
          <div className="row clearfix">
            <div className="about-one_image-column col-lg-6 col-md-12 col-sm-12">
              <div className="about-one_image-inner">
                <Roll left triggerOnce={"false"}>
                  <div className="about-one_image">
                    <img
                      src={eventImage}
                      alt="The 18th UN Tourism Asia/Pacific Executive Training Programme on Tourism Policy and Strategy"
                    />
                  </div>
                </Roll>
              </div>
            </div>
            <div className="about-one_content-column col-lg-6 col-md-12 col-sm-12">
              <div className="about-one_content-inner">
                <div className="sec-title title-anim">
                  <h1
                    className="sec-title_title"
                    style={{ margin: 0, padding: 0 }}>
                    About The Event
                    <h2 className="sec-title_heading">
                      The 18th UN Tourism Asia/Pacific Executive Training
                      Programme on Tourism Policy and Strategy
                    </h2>
                  </h1>
                </div>
                <div className="about-one_text">
                  Since 2006, the UN Tourism has convened the Asia and the
                  Pacific Executive Training Programme on Tourism Policy and
                  Strategy annually thanks to the sponsorship of the Ministry of
                  Culture, Sports and Tourism of the Republic of Korea. This
                  Executive Training Programme is the only programme of its kind
                  in the region and is renowned for its stimulating content and
                  thought-provoking discussions. The theme and subject of the
                  Programme change each year and has historically had
                  far-reaching impacts both for the host destination and the
                  attendees.
                </div>
                <div className="row clearfix">
                  <div className="about-one_block col-lg-6 col-md-6 col-sm-12">
                    <div className="about-one_block-inner">
                      <span className="about-one_block-icon flaticon-map"></span>
                      <strong>Where</strong>
                      Kuching, Malaysia
                    </div>
                  </div>
                  <div className="about-one_block col-lg-6 col-md-6 col-sm-12">
                    <div className="about-one_block-inner">
                      <span className="about-one_block-icon flaticon-calendar-2"></span>
                      <strong>When</strong>
                      October 28 to 31, 2024
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-one_content-column col-lg-12 col-md-12 col-sm-12">
              <div className="about-one_content-inner">
                <div className="about-one_text">
                  The delegates who attend the Executive Training Programme are
                  high-level officials within their respective governments
                  across Asia and the Pacific region. Through a series of
                  lectures, discussions, debates, and presentations, the
                  Training Programme provides a welcoming forum in which public
                  sector tourism executives gain a better understanding of the
                  latest trends in tourism policy. It also provides a platform
                  for sharing knowledge and experience, to learn of successful
                  practices in policy and strategy formulation and
                  implementation. The Training Programme gives participants the
                  opportunity to improve their strategic tourism skills and,
                  through a newfound network of regional contacts, continue to
                  develop these skills going forward. After sixteen years of
                  strong support from the government of the Republic of Korea
                  (ROK), this Programme has become an iconic activity of UN
                  Tourism in the region.
                </div>
                <div className="about-one_text">
                  The overall theme for the 18th UN Tourism Asia/Pacific
                  Executive Training Programme is{" "}
                  <strong>Revisiting and Redefining Luxury Tourism</strong>.
                </div>
                <div className="middle-column">
                  <div className="row clearfix">
                    <div className="column col-lg-12 col-md-12 col-sm-12">
                      <h3 className="event-detail_subtitle">Key Objectives:</h3>
                      <ul className="event-detail_list">
                        <li>
                          Examine the overview of luxury tourism and understand
                          its economic and social importance .
                        </li>
                        <li>
                          Explore the status of Asia Pacific region in relation
                          to the luxury tourism sector and analyze its strengths
                          and weaknesses .
                        </li>
                        <li>
                          Share success stories of luxury tourism in the
                          Asia-Pacific region.
                        </li>
                        <li>
                          Discuss the role of the government and the public
                          sector for developing luxury tourism in this region
                          and discuss ways to cooperate with private sector
                          stakeholders .
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="about-one_text">
                  The programme is specifically designed to support tourism
                  policymakers and managers. It aims at strengthening effective
                  and responsive policies and strategies that in turn must adapt
                  to changing priorities and concerns at the national and
                  destination levels.
                </div>
                <div className="about-one_text">
                  Moreover, the training programme strives to enable UN
                  Tourism's Asia and the Pacific Member Countries to share
                  successful tourism good practices on Luxury Tourism and the
                  above-mentioned topics. The ultimate aim is to strengthen the
                  capacity of destinations on luxury tourism at the regional,
                  sub-regional, national, and destination levels.
                </div>
                <div className="about-one_text">
                  <strong>Press Release:</strong>{" "}
                  <a
                    href="https://www.unwto.org/events/the-18th-unwto-asia-pacific-executive-training-programme-on-tourism-policy-and-strategy"
                    target="_blank"
                    rel="noreferrer">
                    Click here
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="ixHs27TzIqM"
          onClose={() => setOpen(false)}
        />
      </section>
      <section className="speakers-one" id="speakers">
        <div
          className="speakers-one_pattern"
          style={{ backgroundImage: `url(${shape1})` }}></div>
        <div
          className="speakers-one_pattern-two"
          style={{ backgroundImage: `url(${shape2})` }}></div>
        <div className="auto-container">
          <div className="sec-title title-anim centered">
            <div className="sec-title_title">The Event</div>
            <h2 className="sec-title_heading">Organizers and Sponsors</h2>
          </div>
          <div
            className="row clearfix"
            itemScope
            itemType="https://schema.org/Organizer">
            {Teams.map((team, aitem) => (
              <div
                className="speaker-block_one col-lg-3 col-md-6 col-sm-12"
                key={aitem}>
                <div className="speaker-block_one-inner">
                  <div className="speaker-block_one-image">
                    <img itemProp="image" src={team.tImg} alt={team.name} />
                  </div>
                  <div className="speaker-block_one-content">
                    <h4 itemProp="name" className="speaker-one_title">
                      {team.name}
                    </h4>
                    <div itemProp="title" className="speaker-one_detail">
                      {team.title}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <EventsSection />
      <section className="feature-one" id="preArrival">
        <div
          className="feature-one_pattern"
          style={{ backgroundImage: `url(${sIcon})` }}
        />
        <div
          className="feature-one_pattern-two"
          style={{ backgroundImage: `url(${sIcon2})` }}
        />
        <div className="auto-container">
          <div className="sec-title title-anim">
            <div className="sec-title_title">For Foreign Participant</div>
            <h2 className="sec-title_heading">Pre-Arrival</h2>
          </div>
          <div className="row clearfix">
            {featres.map((featres, fitem) => (
              <div
                className="feature-block_one col-lg-6 col-md-6 col-sm-12"
                key={fitem}>
                <div className="feature-block_one-inner">
                  <div className="feature-block_one-icon">
                    <i className={`fi ${featres.fIcon}`}></i>
                  </div>
                  <h4 className="feature-block_one-title">{featres.title}</h4>
                  <div className="feature-block_one-text">
                    {featres.description.map((item, idx) => (
                      <div
                        key={idx}
                        dangerouslySetInnerHTML={{ __html: item }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage;
