import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import MobileMenu from "../MobileMenu/MobileMenu";
import { connect } from "react-redux";
import { removeFromCart } from "../../store/actions/action";
import Logo from "../../images/logo.png";
import pr1 from "../../images/resource/post-thumb-1.jpg";
import pr2 from "../../images/resource/post-thumb-2.jpg";
import pr3 from "../../images/resource/post-thumb-3.jpg";
import paths from "../../main-component/router/paths";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

const Header = (props) => {
  const [searchActive, setSearchState] = useState(false);
  const [cartActive, setCartState] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const handleScroll = () => {
      const hash = window.location.hash.replace("#", "");
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };
    handleScroll();
  }, [navigate]);

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const handleScrollLinkClick = (to) => {
    navigate(`/#${to}`); // Navigate to home page with hash
  };

  return (
    <header className="main-header">
      {/* Header Upper */}
      <div className="header-upper">
        <div className="auto-container">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div className="logo-box">
              <div className="logo">
                {/* <Link onClick={ClickHandler} to="/home">
                  <img src={Logo} alt="" title="" />
                </Link> */}
              </div>
            </div>

            <div className="nav-outer">
              {/* Main Menu */}
              <nav className="main-menu navbar-expand-md">
                <div className="navbar-header">
                  {/* Toggle Button */}
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                </div>

                <div
                  className="navbar-collapse collapse clearfix"
                  id="navbarSupportedContent">
                  <ul className="navigation clearfix">
                    <li>
                      <ScrollLink
                        onClick={() => handleScrollLinkClick("home")}
                        to="home"
                        smooth
                        duration={500}>
                        HOME
                      </ScrollLink>
                    </li>
                    <li>
                      <ScrollLink
                        onClick={() => handleScrollLinkClick("about")}
                        to="about"
                        smooth
                        duration={500}>
                        ABOUT US
                      </ScrollLink>
                    </li>
                    <li>
                      <ScrollLink
                        onClick={() => handleScrollLinkClick("events")}
                        to="events"
                        smooth
                        duration={500}>
                        PROGRAMME
                      </ScrollLink>
                    </li>
                    <li>
                      <ScrollLink
                        onClick={() => handleScrollLinkClick("preArrival")}
                        to="preArrival"
                        smooth
                        duration={500}>
                        PRE-ARRIVAL
                      </ScrollLink>
                    </li>
                    <li>
                      <ScrollLink
                        onClick={() => handleScrollLinkClick("contactUs")}
                        to="contactUs"
                        smooth
                        duration={500}>
                        CONTACT US
                      </ScrollLink>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>

            {/* Main Menu End*/}
            <div className="outer-box d-flex align-items-center flex-wrap">
              {/* Search Btn */}
              {/* <div className="search-box-btn search-box-outer" onClick={() => setSearchState(!searchActive)}><span className="icon fa fa-search"></span></div> */}

              {/* Nav Btn */}
              {/* <div className="nav-btn navSidebar-button" onClick={() => setCartState(!cartActive)}><i className='fi flaticon-shopping-cart'></i></div> */}

              {/* <div className="appointmeny_button-box">
                                <Link onClick={ClickHandler} to="/register" className="theme-btn btn-style-one">
                                    <span className="btn-wrap">
                                        <span className="text-one">Buy Ticket</span>
                                        <span className="text-two">Buy Ticket</span>
                                    </span>
                                </Link>
                            </div> */}

              {/* Mobile Navigation Toggler */}
              <MobileMenu />
            </div>
          </div>
        </div>
      </div>
      {/*End Header Upper*/}
      <div className={`${searchActive ? "search-active" : ""}`}>
        <div className="search-popup">
          <div className="color-layer"></div>
          <button
            className="close-search"
            onClick={() => setSearchState(!searchActive)}>
            <span className="flaticon-cancel-1"></span>
          </button>
          <form method="post" onSubmit={SubmitHandler}>
            <div className="form-group">
              <input
                type="search"
                name="search-field"
                placeholder="Search Here"
                required=""
              />
              <button
                className="fa fa-solid fa-magnifying-glass fa-fw"
                type="submit"></button>
            </div>
          </form>
        </div>
      </div>
      <div
        className={`xs-sidebar-group info-group ${
          cartActive ? "isActive" : ""
        }`}>
        <div
          className="xs-overlay xs-bg-black"
          onClick={() => setCartState(!cartActive)}></div>
        <div className="xs-sidebar-widget">
          <div className="sidebar-widget-container">
            <div
              className="close-button"
              onClick={() => setCartState(!cartActive)}>
              <span className="flaticon-cancel-1"></span>
            </div>
            <div className="sidebar-textwidget">
              <div className="sidebar-info-contents">
                <div className="content-inner">
                  <div className="title-box">
                    <h5>
                      Shopping <span>Bag</span>
                    </h5>
                    <div className="price">$15 from free economy shipping</div>
                  </div>
                  <div className="empty-cart-box">
                    <div className="no-cart">
                      <span className="icon">
                        <i className="fi flaticon-shopping-cart"></i>
                      </span>
                      No products in cart.
                    </div>
                  </div>

                  <div className="lower-box">
                    <h5>
                      Popular <span>Suggestions</span>
                    </h5>
                    <div className="post-block">
                      <div className="inner-box">
                        <div className="image">
                          <img src={pr1} alt="" />
                        </div>
                        <h6>
                          <Link to="/">Children Event</Link>
                        </h6>
                        <div className="rating">
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                        </div>
                        <div className="price-box">$125</div>
                        <Link className="theme-btn bag-btn" to="/">
                          add to bag
                        </Link>
                      </div>
                    </div>
                    <div className="post-block">
                      <div className="inner-box">
                        <div className="image">
                          <img src={pr2} alt="" />
                        </div>
                        <h6>
                          <Link to="/">Marriage Caremoney</Link>
                        </h6>
                        <div className="rating">
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                        </div>
                        <div className="price-box">$205</div>
                        <Link className="theme-btn bag-btn" to="/">
                          add to bag
                        </Link>
                      </div>
                    </div>
                    <div className="post-block">
                      <div className="inner-box">
                        <div className="image">
                          <img src={pr3} alt="" />
                        </div>
                        <h6>
                          <Link to="/">baby shower</Link>
                        </h6>
                        <div className="rating">
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                          <span className="fa fa-star"></span>
                        </div>
                        <div className="price-box">$125</div>
                        <Link className="theme-btn bag-btn" to="/">
                          add to bag
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};

export default connect(mapStateToProps, { removeFromCart })(Header);
