import React, { useState } from 'react';
import { Link } from 'react-scroll'
import { NavLink } from 'react-router-dom'


const MobileMenu2 = () => {
    const [menuActive, setMenuState] = useState(false);

    return (
        <div className={`mobile-menu-wrap ${menuActive ? "mobile-menu-visible" : ""}`}>
            <div className="mobile-nav-toggler" onClick={() => setMenuState(!menuActive)}><span className="icon flaticon-menu"></span></div>
            <div className="mobile-menu">
                <div className="menu-backdrop" onClick={() => setMenuState(!menuActive)}></div>
                <div className="close-btn" onClick={() => setMenuState(!menuActive)}><span className="icon flaticon-multiply"></span></div>
                <nav className="menu-box">
                    <div className="menu-outer">
                        <ul className="navigation">
                            <li><Link activeClass="active" to="home" spy={true} smooth={true} duration={500} offset={-100}>Home</Link></li>
                            <li><Link activeClass="active" to="about" spy={true} smooth={true} duration={500} offset={-100}>About Us</Link></li>
                            <li><Link activeClass="active" to="speakers" spy={true} smooth={true} duration={500} offset={-100}>Speakers</Link></li>
                            <li><Link activeClass="active" to="events" spy={true} smooth={true} duration={500} offset={-100}>Events</Link></li>
                            <li><Link activeClass="active" to="blog" spy={true} smooth={true} duration={500} offset={-150}>Blog</Link></li>
                            <li><NavLink activeClass="active" to="/contact#contact" spy={true} smooth={true} duration={500} offset={-100}>Contact</NavLink></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default MobileMenu2;