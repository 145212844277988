import eimg1 from "../images/resource/leadership-1.jpg";
import eimg2 from "../images/resource/leadership-2.jpg";
import eimg3 from "../images/resource/leadership-3.jpg";
import eimg4 from "../images/resource/leadership-4.jpg";

const schedule = [
  {
    id: 1,
    day: "First Day",
    date: "OCTOBER 28, 2024",
    activitie: [
      {
        activitieId: 1,
        time: "09:30 am -- 10:00 am",
        activitieTitle: "Registration desk for the participants",
        detail: [],
      },
      {
        activitieId: 2,
        time: "10:00 am -- 10:40 am",
        activitieTitle: "Opening Ceremony",
        detail: [
          {
            detailId: 1,
            des: "Traditional Art Performance",
          },
          {
            detailId: 2,
            des: "Welcome remarks by the Ministry of Tourism, Creative Industry and Performing Arts Sarawak",
          },
          {
            detailId: 3,
            des: "Opening remarks by UN Tourism",
          },
          {
            detailId: 4,
            des: "Congratulatory remarks by the Ministry of Tourism, Arts and Culture, Malaysia",
          },
        ],
      },
      {
        activitieId: 3,
        time: "10:40 am -- 10:50 am",
        activitieTitle: "Group Photo",
        detail: [],
      },
      {
        activitieId: 4,
        time: "10:50 am -- 12:30 pm",
        activitieTitle: "UN Tourism Forum on High-end Tourism Market",
        detail: [
          {
            detailId: 1,
            des: "Keynote Presentation by UN Tourism Consultant",
          },
          {
            detailId: 2,
            des: "Panel Discussion moderated by UN Tourism Consultant",
          },
        ],
      },
      {
        activitieId: 5,
        time: "13:00 pm -- 14:00 pm",
        activitieTitle: "Lunch",
        detail: [],
      },
      {
        activitieId: 6,
        time: "14:30 pm -- 17:00 pm",
        activitieTitle: "Session 1: Destination Management Strategies",
        detail: [
          {
            detailId: 1,
            des: "Keynote Speaker (15 minutes)",
          },
          {
            detailId: 2,
            des: "Q&A and Discussion",
          },
          {
            detailId: 3,
            des: "Country Presentations (10 minutes each)",
          },
        ],
      },
      {
        activitieId: 7,
        time: "18:30 pm -- 20:30 pm",
        activitieTitle:
          "Welcome dinner hosted by the Ministry of Tourism, Creative Industry and Performing Arts Sarawak",
        detail: [],
      },
    ],
  },
  {
    id: 2,
    day: "Second Day",
    date: "OCTOBER 29, 2024",
    activitie: [
      {
        activitieId: 1,
        time: "10:00 am -- 12:30 pm",
        activitieTitle: "Session 2: Marketing and Promotion of Luxury Tourism",
        detail: [
          {
            detailId: 1,
            des: "Keynote Speaker (15 minutes)",
          },
          {
            detailId: 2,
            des: "Q&A and Discussion",
          },
          {
            detailId: 3,
            des: "Country Presentations (10 minutes each)",
          },
        ],
      },
      {
        activitieId: 2,
        time: "12:30 pm -- 13:30 pm",
        activitieTitle: "Lunch",
        detail: [],
      },
      {
        activitieId: 3,
        time: "14:00 pm -- 16:30 pm",
        activitieTitle: "Session 3: Customer Experience and Service Excellence",
        detail: [
          {
            detailId: 1,
            des: "Keynote Speaker (15 minutes)",
          },
          {
            detailId: 2,
            des: "Q&A and Discussion",
          },
          {
            detailId: 3,
            des: "Country Presentations (10 minutes each)",
          },
        ],
      },
      {
        activitieId: 4,
        time: "16:30 pm -- 18:30 pm",
        activitieTitle: "Break",
        detail: [],
      },
      {
        activitieId: 5,
        time: "18:30 pm -- 20:30 pm",
        activitieTitle: "Dinner hosted by UN Tourism",
        detail: [],
      },
    ],
  },
  {
    id: 3,
    day: "Third Day",
    date: "OCTOBER 30, 2024",
    activitie: [
      {
        activitieId: 1,
        time: "10:00 am -- 12:30 pm",
        activitieTitle: "Session 4: Investment in Luxury Tourism",
        detail: [
          {
            detailId: 1,
            des: "Keynote Speaker (15 minutes)",
          },
          {
            detailId: 2,
            des: "Q&A and Discussion",
          },
          {
            detailId: 3,
            des: "Country Presentations (10 minutes each)",
          },
        ],
      },
      {
        activitieId: 2,
        time: "12:30 pm -- 13:00 pm",
        activitieTitle: "Break & Voting ‘The Best Country Presentation Award’",
        detail: [],
      },
      {
        activitieId: 3,
        time: "13:00 pm -- 13:30 pm",
        activitieTitle: "Closing Ceremony",
        detail: [
          {
            detailId: 1,
            des: "Conclusion and Recommendation by UN Tourism Consultant",
          },
          {
            detailId: 2,
            des: "Announcement of ‘The Best Country Presentation Award’",
          },
          {
            detailId: 3,
            des: "Award Ceremony of Certificate to Participants",
          },
          {
            detailId: 4,
            des: "Closing remarks by the Ministry of Tourism, Creative Industry and Performing Arts Sarawak",
          },
          {
            detailId: 5,
            des: "Closing remarks by UN Tourism",
          },
        ],
      },
      {
        activitieId: 4,
        time: "13:30 pm -- 14:30 pm",
        activitieTitle: "Lunch",
        detail: [],
      },
      {
        activitieId: 5,
        time: "15:00 pm -- 17:00 pm",
        activitieTitle: "World Tourism Day Celebration (TBC)",
        detail: [],
      },
      {
        activitieId: 6,
        time: "17:00 pm -- 18:30 pm",
        activitieTitle: "Break",
        detail: [],
      },
      {
        activitieId: 7,
        time: "18:30 pm -- 20:30 pm",
        activitieTitle:
          "Farewell Dinner hosted by the Ministry of Tourism, Creative Industry and Performing Arts Sarawak",
        detail: [],
      },
    ],
  },
  {
    id: 4,
    day: "Fourth Day",
    date: "OCTOBER 31, 2024",
    activitie: [
      {
        activitieId: 1,
        time: "09:30 pm -- 16:00 pm",
        activitieTitle: "Technical Tour",
        detail: [],
      },
    ],
  },
];

export default schedule;
