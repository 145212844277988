import React, { useState } from "react";
import AllRoute from "../router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dialog, DialogContent } from "@mui/material";
import nImg from "../../images/resource/about.png";

const App = () => {
  const [open, setOpen] = useState(false);

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  return (
    <div className="App" id="scrool">
      <AllRoute />
      <ToastContainer />

      {/* newslatter-modal */}

      <Dialog open={open}>
        <DialogContent>
          <div className="newsletter-popup-area-section">
            <div className="newsletter-popup-area">
              <div className="newsletter-popup-ineer">
                <div className="close-sidebar-widget close-button newsletter-close-btn">
                  <span
                    onClick={() => setOpen(false)}
                    className="close-btn fi flaticon-cancel-1"></span>
                </div>
                <div className="img-holder">
                  <img src={nImg} alt="" />
                </div>
                <div className="detail-box">
                  <h3>
                    Subscribe and get <span>25% off</span> your first Event.
                  </h3>
                  <div className="newsletter-box">
                    <form method="post" onSubmit={SubmitHandler}>
                      <div className="form-group d-flex flex-wrap">
                        <input
                          aria-label="Input"
                          type="email"
                          name="search-field"
                          value=""
                          placeholder="name@email.com"
                          required
                        />
                        <button
                          aria-label="Button Title"
                          type="submit"
                          className="theme-btn subscribe-btn">
                          subscribe
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="sub-title">Unsubscribe anytime.</div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default App;
