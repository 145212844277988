import classnames from "classnames";
import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Schedule from "../../api/Events";

import { isEmpty } from "lodash";
import sIcon5 from "../../images/background/pattern-10.png";
import sIcon1 from "../../images/icons/icon-1.png";
import sIcon2 from "../../images/icons/icon-2.png";
import sIcon3 from "../../images/icons/icon-3.png";
import sIcon4 from "../../images/icons/icon-4.png";
import BCM1 from "../../images/technical-tour/BCM.png";
import BCM2 from "../../images/technical-tour/BCM2.png";
import BCM3 from "../../images/technical-tour/BCM3.png";
import SWC1 from "../../images/technical-tour/SWC.png";
import SWC2 from "../../images/technical-tour/SWC2.png";
import SWC3 from "../../images/technical-tour/SWC3.png";

const EventsSection = (props) => {
  const [activeTab, setActiveTab] = useState(1);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const currentDay = Schedule.find((activitie) => activitie.id === activeTab);

  return (
    <section className="leadership-one" id="events">
      <div
        className="leadership-one_icon"
        style={{ backgroundImage: `url(${sIcon1})` }}></div>
      <div
        className="leadership-one_icon-two"
        style={{ backgroundImage: `url(${sIcon2})` }}></div>
      <div
        className="leadership-one_icon-three"
        style={{ backgroundImage: `url(${sIcon3})` }}></div>
      <div
        className="leadership-one_icon-four"
        style={{ backgroundImage: `url(${sIcon4})` }}></div>
      <div
        className="leadership-one_icon-five"
        style={{ backgroundImage: `url(${sIcon5})` }}></div>
      <div className="auto-container">
        <div className="sec-title title-anim centered">
          <div className="sec-title_title">Provisional Programme</div>
          <h2 className="sec-title_heading">
            Explore the Latest Schedule &amp; Activities
          </h2>
        </div>
        <div className="leadership-info-tabs">
          <div className="leadership-tabs tabs-box">
            <ul className="">
              <Nav tabs className="tab-btns tab-buttons clearfix">
                {Schedule.map((event, i) => (
                  <NavItem className="tab-btn col-sm-3" key={i}>
                    <NavLink
                      className={classnames({ active: activeTab === event.id })}
                      onClick={() => {
                        toggle(event.id);
                      }}>
                      {event.day} <span>{event.date}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </ul>
            <div className="tabs-content">
              <TabContent activeTab={activeTab}>
                <TabPane tabId={activeTab}>
                  {currentDay.activitie.map((event, i) => (
                    <div className="content" key={i}>
                      <div className="leadership-one_block">
                        <div className="leadership-one_block-inner">
                          <div className="leadership-one_block-content">
                            <div className="leadership-one_block-time">
                              {event.time}
                            </div>
                            <div className="leadership-one_block-name">
                              {event.speaker}
                            </div>
                            <h3 className="leadership-one_block-title">
                              {event.activitieTitle}
                            </h3>
                            {!isEmpty(event.detail) &&
                              event.detail.map((detail, idx) => (
                                <div
                                  key={idx}
                                  className="leadership-one_block-text">
                                  {detail.des}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>

        {activeTab === 4 && (
          <>
            <h6 className="about-one_text" style={{ marginBottom: 10 }}>
              Semenggoh Wildlife Centre
            </h6>
            <div className="row clearfix">
              <div
                className=" col-lg-4 col-md-4 col-sm-12"
                style={{ zIndex: 9999 }}>
                <img
                  src={SWC1}
                  alt="An orangutan stands near the Semenggoh Wildlife Centre entrance sign, with a forest in the background"
                  className="feature-block_one-img"
                />
              </div>
              <div
                className="col-lg-4 col-md-4 col-sm-12"
                style={{ zIndex: 9999 }}>
                <img
                  src={SWC2}
                  alt="Two orangutans rest on a wooden platform in a lush green forest, one sitting, the other lying down"
                  Semenggoh
                  Wildlife
                  Centre
                  className="feature-block_one-img"
                />
              </div>
              <div
                className="col-lg-4 col-md-4 col-sm-12"
                style={{ zIndex: 9999 }}>
                <img
                  src={SWC3}
                  alt="Tourists on a boardwalk observe orangutans in a dense, forested area"
                  className="feature-block_one-img"
                />
              </div>
            </div>
            <div className="about-one_text">
              Semenggoh Wildlife Centre is a renowned wildlife rehabilitation
              facility located about 20km south of Kuching. It was established
              in 1975 and primarily focuses on the conservation and
              rehabilitation of orangutans. Visitors to the centre have the
              opportunity to observe semi-wild orangutans in their natural
              habitat, especially during feeding times.
            </div>

            <div className="about-one_text">
              Key attractions include:
              <ul>
                <li>
                  - Orangutan feeding sessions where you can witness these
                  magnificent creatures up close.
                </li>
                <li>
                  - Nature trails through the lush rainforest, ideal for
                  spotting other wildlife like birds and reptiles.
                </li>
                <li>
                  - Educational displays about conservation efforts and the
                  flora and fauna of the region.
                </li>
              </ul>
            </div>

            <h6 className="about-one_text" style={{ marginBottom: 10 }}>
              Borneo Cultures Museum
            </h6>
            <div className="row clearfix">
              <div
                className="col-lg-4 col-md-4 col-sm-12"
                style={{ zIndex: 9999 }}>
                <img
                  src={BCM1}
                  alt="Borneo Cultures Museum"
                  className="feature-block_one-img"
                />
              </div>
              <div
                className="col-lg-4 col-md-4 col-sm-12"
                style={{ zIndex: 9999 }}>
                <img
                  src={BCM2}
                  alt="Borneo Cultures Museum top view"
                  className="feature-block_one-img"
                />
              </div>
              <div
                className="col-lg-4 col-md-4 col-sm-12"
                style={{ zIndex: 9999 }}>
                <img
                  src={BCM3}
                  alt="Borneo Cultures Museum interior view"
                  className="feature-block_one-img"
                />
              </div>
            </div>
            <div className="about-one_text">
              Borneo Cultures Museum is the largest in Malaysia and showcases
              the rich cultural heritage, history, and biodiversity of Sarawak
              and Borneo island as a whole.
            </div>

            <div className="about-one_text">
              Key attractions include:
              <ul>
                <li>
                  - Exhibits on Borneo’s indigenous tribes, their traditional
                  lifestyles, and craftsmanship.
                </li>
                <li>
                  - Interactive displays on Sarawak’s history, archaeology, and
                  natural environment.
                </li>
                <li>
                  - A children’s gallery with hands-on activities and
                  educational games.
                </li>
                <li>
                  - A rooftop garden with panoramic views of Kuching city.
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default EventsSection;
