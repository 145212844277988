import React, { useState } from "react";
import { Link } from 'react-router-dom'
import BlogSidebar from '../BlogSidebar'
import blogs from '../../api/blogs'
import ModalVideo from "react-modal-video";

const BlogList = (props) => {
    const [isOpen, setOpen] = useState(false);
    const [Url, setUrl] = useState("https://18etpsarawak.com/videos/Interview_Highlight_H-06.mp4");
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="gallery-one mt-5">
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <div className="sec-title_title">
                        The 18th UN Tourism Asia/Pacific Executive Training Programme
                        Memories
                    </div>
                    <h2 className="sec-title_heading">Videos</h2>
                </div>
            </div>
            <div className="row clearfix">

                <div className={`content-side col-lg-12 col-md-12 col-sm-12 ${props.blRight}`}>
                    <div className="our-blog">
                        <div className="row clearfix">
                            {blogs.slice(0, 3).map((blog, Bitem) => (
                                <div className="news-block_one col-lg-4 col-md-4 col-sm-12" key={Bitem}>
                                    <div className="news-block_one-inner">
                                        {/* <div className="news-block_one-tag">{blog.thumb}</div> */}
                                        <div className="news-block_one-image">
                                            <div onClick={() => {setOpen(true);setUrl(blog.videourl)}} ><img src={blog.screens} alt="" /></div>
                                            <img src={blog.screens} alt="" />
                                        </div>
                                        <div className="news-block_one-content">
                                            <h5 className="news-block_one-title"><div onClick={() => {setOpen(true);setUrl(blog.videourl)}}>{blog.title}</div></h5>
                                            <div onClick={() => {setOpen(true);setUrl(blog.videourl)}} className="news-block_one-more">View the Video</div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>

            <ModalVideo
                channel="custom"
                autoplay
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                url={Url}
            />
        </section>
    )

}

export default BlogList;
