import timg1 from "../images/resource/speaker-1.jpg";
import timg2 from "../images/resource/speaker-2.jpg";
import timg3 from "../images/resource/speaker-3.jpg";
import timg4 from "../images/resource/speaker-4.jpg";
import timg5 from "../images/resource/speaker-5.jpg";
import timg6 from "../images/resource/speaker-6.jpg";
import timg7 from "../images/resource/speaker-7.jpg";
import timg8 from "../images/resource/speaker-8.jpg";

import tsimg1 from "../images/resource/team-1.jpg";
import tsimg2 from "../images/resource/team-2.jpg";
import tsimg3 from "../images/resource/team-3.jpg";
import tsimg4 from "../images/resource/team-4.jpg";
import tsimg5 from "../images/resource/team-5.jpg";
import tsimg6 from "../images/resource/team-6.jpg";
import tsimg7 from "../images/resource/team-15.jpg";
import tsimg8 from "../images/resource/team-16.jpg";

import Logo1602 from "../images/logo/1062.png";
import LogoBE from "../images/logo/be.png";
import Logobefr from "../images/logo/befr.jpeg";
import Logoicats from "../images/logo/icats.png";
import Logolhs from "../images/logo/lhs.png";
import Logomtcp from "../images/logo/mtcp.png";
import Logoreborn from "../images/logo/reborn.png";
import Logort1886 from "../images/logo/rt1886.jpeg";
import Logosheraton from "../images/logo/sheraton.png";
import Logosmd from "../images/logo/smd.png";
import Logosrs from "../images/logo/srs.png";
import Logotabby from "../images/logo/tabby.png";
import LogotourismMsia from "../images/logo/tourismMsia.png";
import LogounTourism from "../images/logo/unTourism.png";

const Teams = [
  {
    Id: "1",
    tImg: LogounTourism,
    tsImg: tsimg1,
    name: "UN Tourism",
    slug: "Patrick-Spencer",
    title: "Organizer",
  },
  {
    Id: "2",
    tImg: Logoreborn,
    tsImg: tsimg2,
    name: "Responsible Borneo",
    slug: "Angelina-Holy",
    title: "Professional Congress Organizer",
  },
  {
    Id: "3",
    tImg: Logomtcp,
    tsImg: tsimg3,
    name: "Sarawak, Malaysia",
    slug: "Regina-Blackly",
    title: "Host Country",
  },
  {
    Id: "4",
    tImg: Logoicats,
    tsImg: tsimg6,
    name: "i-CATS UC",
    slug: "Jane-Hamilton",
    title: "Partners",
  },
  {
    Id: "5",
    tImg: LogoBE,
    tsImg: tsimg5,
    name: "Business Events Sarawak",
    slug: "Janet-Jones",
    title: "In-Kind Sponsor",
  },
  {
    Id: "6",
    tImg: LogotourismMsia,
    tsImg: tsimg4,
    name: "Tourism Malaysia",
    slug: "Michael-Dover",
    title: "In-Kind Sponsor",
  },
  // {
  //   Id: "7",
  //   tImg: Logosmd,
  //   tsImg: tsimg7,
  //   name: "Sarawak Tourism Board",
  //   slug: "Jenson-Button",
  //   title: "Sponsors",
  // },
  // {
  //   Id: "8",
  //   tImg: Logo1602,
  //   tsImg: tsimg8,
  //   name: "1602 Craft Beer",
  //   slug: "Harnold-Min",
  //   title: "Sponsors",
  // },
  // {
  //   Id: "9",
  //   tImg: Logobefr,
  //   tsImg: tsimg8,
  //   name: "Borneo Empurau Farm and Resort",
  //   slug: "Harnold-Min",
  //   title: "Sponsors",
  // },
  // {
  //   Id: "10",
  //   tImg: Logolhs,
  //   tsImg: tsimg8,
  //   name: "Lagenda Hills Seafood Restaurant",
  //   slug: "Harnold-Min",
  //   title: "Venue Partners",
  // },
  // {
  //   Id: "11",
  //   tImg: Logort1886,
  //   tsImg: tsimg8,
  //   name: "Round Tower 1886 Cafe and Restaurant",
  //   slug: "Harnold-Min",
  //   title: "Venue Partners",
  // },
  // {
  //   Id: "12",
  //   tImg: Logotabby,
  //   tsImg: tsimg8,
  //   name: "Tabby, Sheraton Kuching Hotel",
  //   slug: "Harnold-Min",
  //   title: "Venue Partners",
  // },
  // {
  //   Id: "13",
  //   tImg: Logosheraton,
  //   tsImg: tsimg8,
  //   name: "Sheraton Kuching Hotel",
  //   slug: "Harnold-Min",
  //   title: "Official Hotel",
  // },
];

export default Teams;
