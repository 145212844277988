import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Download from "yet-another-react-lightbox/plugins/download";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import "yet-another-react-lightbox/styles.css";

const GallerySection3 = (props) => {
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDay, setCurrentDay] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imagesPerPage = 51;

  const imagesArrayLength = {
    0: 13,
    1: 528,
    2: 398,
    3: 302,
    4: 257,
  };

  const imagesArray = Array.from(
    { length: imagesArrayLength[currentDay] },
    (_, index) => ({
      src: `https://18etpsarawak.com/images/day${currentDay}_${index + 1}.jpg`,
    }),
  );

  // Calculate the images to be displayed on the current page
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = imagesArray.slice(indexOfFirstImage, indexOfLastImage);

  // Calculate total pages
  const totalPages = Math.ceil(imagesArray.length / imagesPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Handle image click
  const handleImageClick = (index) => {
    setCurrentImageIndex(indexOfFirstImage + index);
    setOpen(true);
  };

  // Handle tab change
  const toggleDay = (day) => {
    if (currentDay !== day) {
      setCurrentDay(day);
      setCurrentPage(1); // Reset to first page when day changes
    }
  };

  const date = ["27 Oct", "28 Oct", "29 Oct", "30 Oct", "31 Oct"];
  return (
    <section className="gallery-three" style={{ paddingTop: 0 }}>
      <div className="auto-container">
        <div className="leadership-tabs tabs-box">
          <style>
            {`
              .tab-btns {
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                -ms-overflow-style: none;  /* IE and Edge */
                scrollbar-width: none;  /* Firefox */
              }
              .tab-btns::-webkit-scrollbar {
                display: none;  /* Chrome, Safari, and Opera */
              }
              .tab-btn {
                flex: 1 0 auto;
                text-align: center;
              }
            `}
          </style>
          <ul className="">
            <Nav tabs className="tab-btns tab-buttons clearfix">
              {[0, 1, 2, 3, 4].map((day) => (
                <NavItem className="tab-btn col-sm-2" key={day}>
                  <NavLink
                    className={classnames({ active: currentDay === day })}
                    onClick={() => toggleDay(day)}>
                    {date[day]}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </ul>
          <TabContent activeTab={currentDay}>
            <TabPane tabId={currentDay}>
              <div className="row clearfix">
                {currentImages.map((item, pitem) => (
                  <div
                    className="gallery-one_block col-lg-4 col-md-4 col-sm-6 col-4"
                    key={pitem}>
                    <div className="gallery-one_block-inner">
                      <div
                        className="gallery-one_block-image"
                        onClick={() => handleImageClick(pitem)}>
                        <img src={item.src} alt="ETP Sarawak" />
                        <div className="gallery-one_block-overlay">
                          <span className="gallery-one_block-icon flaticon-plus-symbol"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={currentPage === index + 1 ? "active" : ""}>
                    {index + 1}
                  </button>
                ))}
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={imagesArray}
        plugins={[Zoom, Thumbnails, Fullscreen, Download]}
        index={currentImageIndex}
      />
    </section>
  );
};

export default GallerySection3;
