import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import thumbnail from "../../images/video_thumbnail.png";

const VideoArea = (props) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <section
      className="video-one"
      style={{
        backgroundImage: `url(${thumbnail})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}>
      <div className="auto-container mt-5">
        <div className="sec-title title-anim light centered">
          <div className="sec-title_title">Event Video</div>
          <h2 className="sec-title_heading">
            Watch the video to <br /> know more about the event!
          </h2>
          <button
            onClick={() => setOpen(true)}
            className="lightbox-video video-one_play">
            <span className="fa fa-play">
              <i className="ripple"></i>
            </span>
          </button>
        </div>
      </div>
      <ModalVideo
        channel="custom"
        autoplay
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        url="https://18etpsarawak.com/videos/UN_Tourism_H03.mp4"
      />
    </section>
  );
};

export default VideoArea;
