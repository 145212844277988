import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo.png";

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <footer className="main-footer" id="contactUs">
      <div className="auto-container">
        <div className="widgets-section">
          <div className="row clearfix">
            <div className="big-column col-lg-4 col-md-12 col-sm-12">
              <div className="footer-column">
                <div className="footer-text">
                  Welcome to the 18th UN Tourism Asia/Pacific Executive Training
                  Programme. This year, we are excited to bring you the best of
                  the best in the industry. We hope you enjoy your time here and
                  make the most of it.
                </div>
              </div>
            </div>
            <div className="big-column col-lg-8 col-md-12 col-sm-12">
              <div className="footer-column">
                <div className="row clearfix">
                  <div className="footer-info-column col-lg-3 col-md-4 col-sm-12">
                    <span className="footer-info-icon flaticon-message"></span>
                    <h6 className="footer-info_title">Mr. Nicholas Tiong</h6>
                    <div style={{ color: "lightgrey" }}>
                      Director,
                      <br />
                      Responsible Borneo
                    </div>
                    <div className="footer-info_text">
                      {" "}
                      {/* add direct link to email address */}
                      <a href="mailto:tiongchiongyieu@gmail.com">
                        tiongchiongyieu@gmail.com
                      </a>
                    </div>
                  </div>
                  <div className="footer-info-column col-lg-3 col-md-4 col-sm-12">
                    <span className="footer-info-icon flaticon-message"></span>
                    <h6 className="footer-info_title">Mr. Jerry Chin</h6>
                    <div style={{ color: "lightgrey" }}>
                      General Manager,
                      <br />
                      Responsible Borneo
                    </div>
                    <div className="footer-info_text">
                      {" "}
                      {/* add direct link to email address */}
                      <a href="mailto:jerrychin5175@gmail.com ">
                        jerrychin5175@gmail.com
                      </a>
                    </div>
                  </div>
                  <div className="footer-info-column col-lg-3 col-md-4 col-sm-12">
                    <span className="footer-info-icon flaticon-message"></span>
                    <h6 className="footer-info_title">Mr. Sinyoung An</h6>
                    <div style={{ color: "lightgrey" }}>
                      Senior Officer,
                      <br />
                      Regional Department for Asia and the Pacific, UN Tourism
                    </div>
                    <div className="footer-info_text">
                      {" "}
                      {/* add direct link to email address */}
                      <a href="mailto:san@unwto.org ">san@unwto.org</a>
                    </div>
                  </div>
                  <div className="footer-info-column col-lg-3 col-md-4 col-sm-12">
                    <span className="footer-info-icon flaticon-message"></span>
                    <h6 className="footer-info_title">Ms. Orianne Derrier</h6>
                    <div style={{ color: "lightgrey" }}>
                      Senior Regional Project Specialist,
                      <br />
                      Regional Department for Asia and the Pacific, UN Tourism
                    </div>
                    <div className="footer-info_text">
                      {" "}
                      {/* add direct link to email address */}
                      <a href="mailto: oderrier@unwto.org">
                        oderrier@unwto.org
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="column col-lg-6 col-md-12 col-sm-12">
              <div className="copyright">
                &copy; Copyright ©2024. All Rights Reserved by Responsible
                Borneo. Powered by Wisdom Digital Solution Sdn Bhd
              </div>
            </div>
            <div className="column col-lg-6 col-md-12 col-sm-12">
              <ul className="footer-bottom-nav">
                <li>
                  <Link onClick={ClickHandler} to="/terms">
                    Terms and conditions
                  </Link>
                </li>
                <li>
                  <Link onClick={ClickHandler} to="/privacy">
                    Privacy policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
