import React from "react";
import { Link } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const GallerySection = (props) => {
  const [open, setOpen] = React.useState(false);

  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

  const thumbnailsRef = React.useRef(null);

  const data = props !== undefined && props.image;

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  // Handle image click
  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setOpen(true);
  };

  return (
    <section className="gallery-one mt-5">
      <div className="auto-container">
        <div className="sec-title title-anim centered">
          <div className="sec-title_title">
            The 18th UN Tourism Asia/Pacific Executive Training Programme
            Memories
          </div>
          <h2 className="sec-title_heading">Gallery</h2>
        </div>
      </div>
      <div className="outer-container">
        <div className="clearfix">
          {data.image.map((item, idx) => (
            <div
              className="gallery-one_block col-lg-3 col-md-4 col-sm-6"
              key={idx}>
              <div className="gallery-one_block-inner">
                <div
                  className="gallery-one_block-image"
                  onClick={() => handleImageClick(idx)}>
                  <img src={item.src} alt={item} />
                  <div className="gallery-one_block-overlay">
                    <span className="gallery-one_block-icon flaticon-plus-symbol"></span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "2vw",
        }}>
        <Link
          onClick={ClickHandler}
          to="/gallery"
          className="theme-btn btn-style-one">
          <span className="btn-wrap">
            <span className="text-one">More Images</span>
            <span className="text-two">More Images</span>
          </span>
        </Link>
      </div>
      <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={data.image}
        plugins={[Zoom, Thumbnails, Fullscreen, Download]}
        thumbnails={{ ref: thumbnailsRef }}
        on={{
          click: () => {
            (thumbnailsRef.current?.visible
              ? thumbnailsRef.current?.hide
              : thumbnailsRef.current?.show)?.();
          },
        }}
        index={currentImageIndex}
      />
    </section>
  );
};

export default GallerySection;
