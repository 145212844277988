import React, { Fragment } from "react";
import Footer from "../../components/footer/Footer";
import GallerySection3 from "../../components/GallerySection3/GallerySection3";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";

const GalleryPage = (props) => {
  return (
    <Fragment>
      <Navbar />
      <PageTitle
        pageTitle={"Gallery"}
        pagesub={"Gallery"}
        pageTitle2={"Gallery"}
      />
      <GallerySection3 />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default GalleryPage;
